import { useMutation, useQueryClient } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions";
import { SubmitHandler, useForm } from "react-hook-form";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Label,
  Row,
  TextInput,
} from "../../components";
import { functions } from "../../firebase";
import { ResultCategory } from "../../services";

interface Inputs {
  name: string;
  maxScore: string;
  judge1: string;
  judge2: string | null;
  judge3: string | null;
}

interface CategoryForm extends Inputs {
  competitionId: string;
  categoryId?: string;
}

interface CreateCategoryModalProps {
  category?: ResultCategory;
  competitionId?: string;
  onClickClose: () => void;
}

const CreateCategoryModal: React.FC<CreateCategoryModalProps> = ({
  category,
  competitionId,
  onClickClose,
}) => {
  const {
    register,
    setError,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: category
      ? {
          name: category.name,
          maxScore: category.maxScore.toString(),
          judge1: category.judge1,
          judge2: category.judge2,
          judge3: category.judge3,
        }
      : {
          judge1: "Juez C",
          judge2: "Juez M",
          judge3: "Juez E",
        },
  });

  const queryClient = useQueryClient();

  const createCategory = useMutation({
    mutationFn: (form: CategoryForm) => {
      const createCategory = httpsCallable(functions, "createCategory");
      return createCategory(form);
    },
    onSuccess: async (data) => {
      await queryClient.setQueryData(["competition", competitionId], data.data);
      onClickClose();
    },
  });

  const handleErrors = (data: Inputs) => {
    let errors = false;

    if (!data.name) {
      setError("name", { type: "required" });
      errors = true;
    }

    if (!data.maxScore) {
      setError("maxScore", { type: "required" });
      errors = true;
    }

    if (!data.judge1) {
      setError("judge1", { type: "required" });
      errors = true;
    }

    if (!data.judge2 && data.judge3) {
      setError("judge2", { type: "required" });
      errors = true;
    }

    return errors;
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!handleErrors(data)) {
      createCategory.mutate({
        ...data,
        categoryId: category?.id,
        competitionId: competitionId || "",
      });
    }
  };

  const judge2 = watch("judge2");
  const judge3 = watch("judge3");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {errors.judge2 ? (
        <FormGroup>
          <Alert type="error">El juez 2 no puede estar vacío.</Alert>
        </FormGroup>
      ) : null}

      {createCategory.error ? (
        <FormGroup>
          <Alert type="error">
            Ha ocurrido un error al crear la categoría.
          </Alert>
        </FormGroup>
      ) : null}

      <FormGroup>
        <TextInput
          label="Nombre de la categoría"
          hasError={!!errors.name}
          {...register("name")}
        />
      </FormGroup>

      <FormGroup>
        <TextInput
          label="Puntuación máxima"
          hasError={!!errors.maxScore}
          {...register("maxScore")}
        />
      </FormGroup>

      <FormGroup>
        <Row>
          <Col>
            <TextInput
              label="Juez 1"
              hasError={!!errors.judge1}
              {...register("judge1")}
            />
          </Col>

          <Col>
            <Label>Juez 2</Label>
            {judge2 != null ? (
              <TextInput
                hasError={!!errors.judge2}
                {...register("judge2")}
                rightAddon={
                  <DeleteIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#F44336",
                      cursor: "pointer",
                    }}
                    onClick={() => setValue("judge2", null)}
                  />
                }
              />
            ) : (
              <Button
                type="success"
                block
                onClick={() => setValue("judge2", "Juez M")}
              >
                Añadir juez
              </Button>
            )}
          </Col>

          <Col>
            <Label>Juez 3</Label>
            {judge3 != null ? (
              <TextInput
                hasError={!!errors.judge3}
                {...register("judge3")}
                rightAddon={
                  <DeleteIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#F44336",
                      cursor: "pointer",
                    }}
                    onClick={() => setValue("judge3", null)}
                  />
                }
              />
            ) : (
              <Button
                type="success"
                block
                onClick={() => setValue("judge3", "Juez E")}
              >
                Añadir juez
              </Button>
            )}
          </Col>
        </Row>
      </FormGroup>

      <Button submit block isLoading={createCategory.isPending}>
        {category ? "Editar categoría" : "Crear categoría"}
      </Button>
    </form>
  );
};

export { CreateCategoryModal };
