import { useMutation } from "@tanstack/react-query";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { Button, Modal } from "../../components";
import { firestore } from "../../firebase";
import { Competition } from "../../services";
import styles from "./styles.module.scss";

interface DeleteCompetitionProps {
  competition: Competition;
}

const DeleteCompetition: React.FC<DeleteCompetitionProps> = ({
  competition,
}) => {
  const [showDelete, setShowDelete] = useState(false);

  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: () => {
      const docRef = doc(firestore, "competitions", competition.id);
      return updateDoc(docRef, { deleted: true });
    },
    onSuccess: () => {
      navigate("/competitions");
    },
  });

  return (
    <>
      <Button
        type="danger-link"
        block
        style={{ marginTop: "1rem" }}
        onClick={() => setShowDelete(true)}
      >
        <DeleteIcon />
        Eliminar competición
      </Button>

      <Modal
        title="Eliminar competición"
        isOpen={showDelete}
        onClickClose={() => setShowDelete(false)}
      >
        <div>
          ¿Estás seguro que quieres eliminar la competición{" "}
          <b>{competition?.name}</b>?
        </div>

        <div className={styles.modalButtons}>
          <Button type="light" onClick={() => setShowDelete(false)}>
            Cancelar
          </Button>

          <Button
            type="danger"
            isLoading={deleteMutation.isPending}
            onClick={deleteMutation.mutate}
          >
            Eliminar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { DeleteCompetition };
